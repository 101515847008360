.ClusterGraphCanvas {
  width: 700px;
  height: 700px;

  grid-area: canvas;

  background-color: #fafafa;

  border: 0.0625rem solid #aaa;
}
