.GraphAccordion {
  &Header {
    font-weight: bold;
    color: #555;
    cursor: pointer;
    border: 0.0625rem solid #aaa;
    padding: 0.5em 0.5em 0.5em 0.7em;
    border-radius: 0.33333333333em;
    background: linear-gradient(#eaeaea, #dadada, #eaeaea);
  }

  &Content {
    border: 0.0625rem solid rgba(0, 0, 0, 0.08);
    border-top: none;
    padding: 0.5em;
  }
}

.GraphAccordion[open] .GraphAccordionHeader {
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: none;
}
