.ClusterGraphDisplay {
  display: grid;
  grid-template-areas:
    'header       header       header     '
    'controls     canvas       information';
  grid-template-columns: 24em 700px 41.5em;
  gap: 0.25em;

  .Header {
    grid-area: header;

    max-width: calc(100vw - 2rem);

    display: flex;
    align-items: center;
    justify-content: space-between;

    .HelpTrigger {
      margin-left: 0.25em !important;
    }

    .wdk-Icon {
      font-size: 1em;
    }
  }

  .BackToGroupPageLink {
    font-size: 1.5em;
    margin-left: 3em;

    button.btn {
      background: #414c9b;
      color: white;
      border-bottom-color: #20264c;
    }
  }
}
