.wdk-RecordTable > .Mesa.MesaComponent > .MesaComponent > .DataTable {
  max-height: 50em;
  overflow: auto;
}

.Cell-legend {
  .PfamDomain {
    width: 16.6666666667em;
  }
}

.phyletic-distribution-tooltip {
  position: relative;
  display: inline-block;
}

.phyletic-distribution-tooltip .phyletic-distribution-tooltiptext {
  top: 50%;
  right: 105%;
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.phyletic-distribution-tooltip:hover .phyletic-distribution-tooltiptext {
  visibility: visible;
}
